body,
html,
html,
#root {
  height: 100%;
}

.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer img {
  margin-left: 0.5rem;
}

a {
  text-decoration: none;
  color: #0070f3;
}

a:hover,
a:focus,
a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title img {
  vertical-align: middle;
}

.title,
.description {
  text-align: center;
}

.description {
  line-height: 1.5;
  font-size: 1.5rem;
}

code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  max-width: 800px;
  margin-top: 3rem;
}

.card {
  margin: 1rem;
  flex-basis: 45%;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h3 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 2em;
  padding: 10px;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

/** List */

form {
  display: table;
  min-width: 350px;
  margin: 0 auto;
  padding: 1rem;
}

label {
  display: table;
  width: 100%;
  margin-bottom: 0.5rem;
  color: #3b3b3b;
}

input,
textarea {
  display: table;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
  margin-bottom: 2.5rem;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #0070f3;
}

/*button {*/
/*  display: table;*/
/*  width: 100%;*/
/*  padding: 0.5rem;*/
/*  border: none;*/
/*  border-radius: 0.25rem;*/
/*  box-sizing: border-box;*/
/*  margin-bottom: 2.5rem;*/
/*  background-color: #0070f3;*/
/*  color: #fff;*/
/*  cursor: pointer;*/
/*}*/

button:hover {
  background-color: #0060e9;
}

button:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

/** View */

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h3 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

/** Gigs */

.header {
  font-size: 5rem;
  margin-bottom: 5rem;
}

.full-flex-centered {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hover-scale {
  transition: all 0.2s ease-in-out;
}
.hover-scale:hover {
  transform: scale(1.1);
}

.launch-receiver {
  min-width: 9.5rem;
  background: #5203fc;
  color: white;
  border-radius: 8px;
  height: 2.5rem;
  font-weight: bold;
  font-size: 0.95rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.receiver-is-open {
  border-radius: 8px;
  color: black;
  height: 2.5rem;
  min-width: 10rem;
  background: white;
  border-radius: 8px;
  height: 2rem;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.mb1 {
  margin-bottom: 1rem;
}

.dyn-address-input {
  min-width: 25rem;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background-color: white;
  color: black;
  outline: none;
  cursor: pointer;
  padding-left: 1rem;
  border: 4px solid black;
}
